import { Component, Injector, ViewChild } from '@angular/core';
import { LoaderUtils } from '@bv/ionic';
import { ZigConstants } from '../../../constants/zig';
import { FieldDefinitions } from '../../../models/field-definition';
import { ZigService } from '../../../providers/services/business/zig.service';
import { TranslateService } from '@ngx-translate/core';
import { BasePageComponent } from '../../base-page-component';
import { IonModal } from '@ionic/angular';
import { BvAlertController } from '@bv/ionic';
import { ZigZipModel } from 'src/app/models/zig/zig-export-list.model';

@Component({
    selector: 'page-zig-doc-export-list',
    templateUrl: 'zig-doc-export-list.component.html'
  })
  export class ZigDocExportListComponent extends BasePageComponent {
    
    //injections
    private readonly alertCtrl = this.injector.get<BvAlertController>(BvAlertController);
    public readonly zigSrv = this.injector.get<ZigService>(ZigService);
    private readonly translateSrv = this.injector.get<TranslateService>(TranslateService);

    @ViewChild(IonModal) modal!: IonModal;

    constructor(protected injector: Injector) {
      super(injector, 'ZigDocExportListComponent');
    }

    ngOnInit(): void {
      this.loadTableData();
    }

    private loadTableData(): void {
      // Start loading
      LoaderUtils.presentLoading(this.injector).then(loading => {
        // Load data
        this.zigSrv.getZipFilesGenerated()
          .subscribe(
            (result: ZigZipModel[]) => {
              this.lines = result;
              // Stop loading
              loading.dismiss();
            },
            (error: any) => {
              this.logger.error('Error during loading', error);
              // Stop loading
              loading.dismiss();
            }
          );
      });
    }

    protected load(): void {
    }
    
    public onChange(event: string, field: string): void {
    }

    protected getFields(): FieldDefinitions {
      return ZigConstants.FIELDS_ZIG_EXPORT;
    }

    
}