import { ObjectUtils, StringUtils } from '@bv/angular-commons';
import { BvHttpRequestDescriptor } from '@bv/angular-core';
import { AuditorAssessmentFilterModel } from '../components/auditor-assessment/aa-filter/aa-filter.component';
import { AwiFilterModel } from '../components/awi/awi-filter/awi-filter.component';
import { CdmFilterModel } from '../components/cdm/cdm-filter/cdm-filter.component';
import { Sequence6FilterModel } from '../components/sequence6/sequence6-filter/sequence6-filter.component';
import { ShirleyFilterModel } from '../components/shirley/shirley-filter/shirley-filter.component';
import { EtqFilterModel } from '../components/etq/etq-filter/etq-filter.component';
import { ZigFilterModel } from '../components/zig/zig-filter/zig-filter.component';
import { AwiDocumentModel } from '../models/awi/awi-awidoc-document.model';
import { GsitRfcDocumentModel } from '../models/gsit/gsit-rfc-document.model';
import { GsitShipmentDocumentModel } from '../models/gsit/gsit-shipment-document.model';
import { IsmPurchaseDocumentModel } from '../models/ism-purchase/ism-purchase-document.model';
import { Sequence6DocumentModel } from '../models/sequence6/sequence6-document.model';
import { EtqDocumentModel } from '../models/etq/etq-document.model';
import { ZigDocumentModel } from '../models/zig/zig-doc-document.model';
import { UrlUtils } from '../utils/url-utils';
import { AuditorAssessmentConstants } from './auditor-assessment';
import { AwiConstants } from './awi';
import { CdmConstants } from './cdm';
import { GsitConstants } from './gist';
import { Sequence6Constants } from './sequence6';
import { ShirleyConstants } from './shirley';
import { EtqConstants } from './etq';
import { ZigConstants } from './zig';

class WebServicesDefinition {
	[key: string]: BvHttpRequestDescriptor;
}

export abstract class WebServices {

	 /**
	 * Allow to customize the given BvHttpRequestDescriptor
	 */
	public static parametrize(ws: BvHttpRequestDescriptor, ...params: any): BvHttpRequestDescriptor {
		return ws.parametrize ? ws.parametrize(...params) : ws;
	}


	public static readonly AUTH: WebServicesDefinition = {
		POST: {
			url: 'authenticate',
			method: 'POST',
			parametrize: (usePasswordBase64: string) => {
				const clone = Object.assign({}, WebServices.AUTH.POST);
				clone.headers = {
					Authorization: usePasswordBase64
				};
				return clone;
			}
		}
	};

	public static readonly CDM_PROJECT: WebServicesDefinition = {
		GET_FILTERDATA: {
			url: 'cdm/project/filters/generic',
			method: 'GET',
			parametrize: (field: string) => {
				const clone = Object.assign({}, WebServices.CDM_PROJECT.GET_FILTERDATA);
				clone.url = UrlUtils.addParameters(
					clone.url,
					[
						{ key: 'field', value: field }
					]
				);
				return clone;
			}
		},
		GET_ALL: {
			url: 'cdm/project/search',
			method: 'GET',
			parametrize: (filters: CdmFilterModel) => {
				const clone = Object.assign({}, WebServices.CDM_PROJECT.GET_ALL);
				clone.url = UrlUtils.addParameters(
					clone.url,
					[
						{ key: CdmConstants.FIELDS_PROJECT.SERVICE_TYPE.name, value: filters.serviceType },
						{ key: CdmConstants.FIELDS_PROJECT.PROJECT_TITLE.name, value: filters.projectTitle },
						{ key: CdmConstants.FIELDS_PROJECT.PROJECT_ID.name, value: filters.projectId },
						{ key: CdmConstants.FIELDS_PROJECT.PROJECT_ITR.name, value: filters.itr },
						{ key: CdmConstants.FIELDS_PROJECT.CONTRACT_NUMBER.name, value: filters.contractNumber },
						{ key: CdmConstants.FIELDS_PROJECT.HOST_COUNTRY.name, value: filters.hostCountry },
						{ key: CdmConstants.FIELDS_PROJECT.LEAD_VERIFIER.name, value: filters.leadVerifier },
						{ key: CdmConstants.FIELDS_PROJECT.DOCTYPE_NAME.name, value: filters.doctypeName },
						{ key: CdmConstants.FIELDS_PROJECT.LAST_VERSION.name, value: filters.isLastVersion },
						{ key: CdmConstants.FIELDS_PROJECT.DATE_REGISTRATION.name, value: filters.registrationDate }
					]
				);
				return clone;
			}
		},
		GET_DOWNLOAD: {
			url: 'cdm/project/download',
			method: 'GET',
			responseType: 'json',
			parametrize: (doc: any) => {
				const clone = Object.assign({}, WebServices.CDM_PROJECT.GET_DOWNLOAD);
				clone.url = UrlUtils.addParameters(
					clone.url,
					[{ key: 'filename', value: ObjectUtils.readProperty(doc, '_id') }]
				);
				return clone;
			}
		},
		GET_AUTOCOMPLETEDATA: {
			url: 'cdm/project/autocomplete/generic',
			method: 'GET',
			parametrize: (field: string, value: any) => {
				const clone = Object.assign({}, WebServices.CDM_PROJECT.GET_AUTOCOMPLETEDATA);
				clone.url = UrlUtils.addParameters(
					clone.url, [{ key: 'field', value: field }, { key: 'value', value }]
				);
				return clone;
			}
		}
	};

	public static readonly SHIRLEY_JOB: WebServicesDefinition = {
		GET_FILTERDATA: {
			url: 'shirley/job/filters/generic',
			method: 'GET',
			parametrize: (field: string) => {
				const clone = Object.assign({}, WebServices.SHIRLEY_JOB.GET_FILTERDATA);
				clone.url = UrlUtils.addParameters(
					clone.url,
					[
						{ key: 'field', value: field }
					]
				);
				return clone;
			}
		},
		GET_ALL: {
			url: 'shirley/job/search',
			method: 'GET',
			parametrize: (filters: ShirleyFilterModel) => {
				const clone = Object.assign({}, WebServices.SHIRLEY_JOB.GET_ALL);
				let date_from: string | undefined = StringUtils.isBlank(filters.creation_date_from) ? undefined : filters.creation_date_from;
				let date_until: string | undefined = StringUtils.isBlank(filters.creation_date_until) ? undefined : filters.creation_date_until;
				if (date_from !== undefined || date_until !== undefined) {
					// Date completion form
					date_from = filters.creation_date_from ? filters.creation_date_from : '1970-01-01';
					// Date completion until
					date_until = filters.creation_date_until ? filters.creation_date_until : undefined;
					if (date_until) {
						const newDate = new Date(date_until);
						newDate.setDate(newDate.getDate() + 1);
						newDate.setUTCHours(0);
						newDate.setUTCMinutes(0);
						newDate.setUTCSeconds(0);
						newDate.setUTCMilliseconds(0);
						date_until = newDate.toISOString();
					} else {
						date_until = '2099-12-31';
					}
				}
				clone.url = UrlUtils.addParameters(
					clone.url,
					[
						{ key: ShirleyConstants.FIELDS.JOB_NUMBER, value: filters.job_number },
						{ key: ShirleyConstants.FIELDS.OFFICE, value: filters.office },
						{ key: ShirleyConstants.FIELDS.PORT, value: filters.port },
						{ key: ShirleyConstants.FIELDS.VESSEL, value: filters.vessel === undefined ? undefined : encodeURI(filters.vessel) },
						{ key: ShirleyConstants.FIELDS.PRODUCT, value: filters.product === undefined ? undefined : encodeURI(filters.product) },
						{ key: ShirleyConstants.FIELDS.TERMINAL, value: filters.terminal === undefined ? undefined : encodeURI(filters.terminal) },
						{ key: ShirleyConstants.FIELDS.CUSTOMER, value: filters.customer === undefined ? undefined : encodeURI(filters.customer) },
						{ key: ShirleyConstants.FIELDS.CARGO_OPERATION, value: filters.cargo_operation },
						{ key: ShirleyConstants.FIELDS.JOB_STATUS, value: filters.status },
						{ key: ShirleyConstants.FIELDS.CREATION_DATE_FROM, value: date_from },
						{ key: ShirleyConstants.FIELDS.CREATION_DATE_UNTIL, value: date_until }
					]
				);
				return clone;
			}
		},
		GET_DOWNLOAD: {
			url: 'shirley/job/download',
			method: 'GET',
			responseType: 'json',
			parametrize: (doc: any) => {
				const clone = Object.assign({}, WebServices.SHIRLEY_JOB.GET_DOWNLOAD);
				clone.url = UrlUtils.addParameters(
					clone.url,
					[{ key: 'filename', value: ObjectUtils.readProperty(doc, 'id') }]
				);
				return clone;
			}
		},
		GET_AUTOCOMPLETEDATA: {
			url: 'shirley/job/autocomplete/generic',
			method: 'GET',
			parametrize: (field: string, value: any) => {
				const clone = Object.assign({}, WebServices.SHIRLEY_JOB.GET_AUTOCOMPLETEDATA);
				const encoded = encodeURI(value);
				clone.url = UrlUtils.addParameters(
					clone.url, [{ key: 'field', value: field }, { key: 'value', value: encoded }]
				);
				return clone;
			}
		}
	};


	public static readonly CDM_PROPOSAL: WebServicesDefinition = {
		GET_FILTERDATA: {
			url: 'cdm/proposal/filters/generic',
			method: 'GET',
			parametrize: (field: string) => {
				const clone = Object.assign({}, WebServices.CDM_PROPOSAL.GET_FILTERDATA);
				clone.url = UrlUtils.addParameters(clone.url, [{ key: 'field', value: field }]);
				return clone;
			}
		},
		GET_ALL: {
			url: 'cdm/proposal/search',
			method: 'GET',
			parametrize: (filters: CdmFilterModel) => {
				const clone = Object.assign({}, WebServices.CDM_PROPOSAL.GET_ALL);
				clone.url = UrlUtils.addParameters(
					clone.url,
					[
						{ key: CdmConstants.FIELDS_PROPOSAL.SERVICE_TYPE.name, value: filters.serviceType },
						{ key: CdmConstants.FIELDS_PROPOSAL.SCHEME.name, value: filters.scheme },
						{ key: CdmConstants.FIELDS_PROPOSAL.PROPOSAL_PROJECT_NAME.name, value: filters.proposalProjectName },
						{ key: CdmConstants.FIELDS_PROPOSAL.CLIENT_NAME.name, value: filters.clientName },
						{ key: CdmConstants.FIELDS_PROPOSAL.HOST_COUNTRY.name, value: filters.hostCountry },
						{ key: CdmConstants.FIELDS_PROPOSAL.DOCTYPE_NAME.name, value: filters.doctypeName },
						{ key: CdmConstants.FIELDS_PROPOSAL.LAST_VERSION.name, value: filters.isLastVersion }
					]
				);
				return clone;
			}
		},
		GET_DOWNLOAD: {
			url: 'cdm/proposal/download',
			method: 'GET',
			responseType: 'json',
			parametrize: (doc: any) => {
				const clone = Object.assign({}, WebServices.CDM_PROPOSAL.GET_DOWNLOAD);
				clone.url = UrlUtils.addParameters(
					clone.url,
					[{ key: 'filename', value: ObjectUtils.readProperty(doc, '_id') }]
				);
				return clone;
			}
		},
		GET_AUTOCOMPLETEDATA: {
			url: 'cdm/proposal/autocomplete/generic',
			method: 'GET',
			parametrize: (field: string, value: any) => {
				const clone = Object.assign({}, WebServices.CDM_PROPOSAL.GET_AUTOCOMPLETEDATA);
				clone.url = UrlUtils.addParameters(
					clone.url, [{ key: 'field', value: field }, { key: 'value', value }]
				);
				return clone;
			}
		}
	};

	public static readonly AUDITOR_ASSESSMENT: WebServicesDefinition = {
		GET_FILTERDATA: {
			url: 'auditor_assessment/assessment/filters/generic',
			method: 'GET',
			parametrize: (field: string) => {
				const clone = Object.assign({}, WebServices.AUDITOR_ASSESSMENT.GET_FILTERDATA);
				clone.url = UrlUtils.addParameters(clone.url, [{ key: 'field', value: field }]);
				return clone;
			}
		},
		GET_ALL: {
			url: 'auditor_assessment/assessment/search',
			method: 'GET',
			parametrize: (filters: AuditorAssessmentFilterModel) => {
				const clone = Object.assign({}, WebServices.AUDITOR_ASSESSMENT.GET_ALL);
				clone.url = UrlUtils.addParameters(
					clone.url,
					[
						{ key: AuditorAssessmentConstants.FIELDS_ASSESSMENT.LAST_NAME.name, value: filters.lastName },
						{ key: AuditorAssessmentConstants.FIELDS_ASSESSMENT.REFERENTIAL.name, value: filters.referential },
						{ key: AuditorAssessmentConstants.FIELDS_ASSESSMENT.AUDITOR_STATUS.name, value: filters.auditorStatus },
						{ key: AuditorAssessmentConstants.FIELDS_ASSESSMENT.ACTIVITY_CODE.name, value: filters.activityCode },
						{ key: AuditorAssessmentConstants.FIELDS_ASSESSMENT.MANAGER.name, value: filters.manager },
						{ key: AuditorAssessmentConstants.FIELDS_ASSESSMENT.STATUS.name, value: filters.status },
						{ key: AuditorAssessmentConstants.FIELDS_ASSESSMENT.COUNTRY.name, value: filters.country },
						{ key: AuditorAssessmentConstants.FIELDS_ASSESSMENT.IS_LAST_VERSION.name, value: filters.isLastVersion }
					]
				);
				return clone;
			}
		},
		GET_DOWNLOAD: {
			url: 'auditor_assessment/assessment/download',
			method: 'GET',
			responseType: 'json',
			parametrize: (doc: any) => {
				const clone = Object.assign({}, WebServices.AUDITOR_ASSESSMENT.GET_DOWNLOAD);
				clone.url = UrlUtils.addParameters(
					clone.url,
					[{ key: 'filename', value: ObjectUtils.readProperty(doc, '_id') }]
				);
				return clone;
			}
		}
	};

	public static readonly GSIT_RFC: WebServicesDefinition = {
		GET_ALL: {
			url: 'gsit/rfc/search',
			method: 'GET',
			parametrize: (programCode: string, year: number, num: number) => {
				const clone = Object.assign({}, WebServices.GSIT_RFC.GET_ALL);
				clone.url = UrlUtils.addParameters(
					clone.url,
					[
						{ key: GsitConstants.FIELDS.PROGRAM_CODE, value: programCode },
						{ key: GsitConstants.FIELDS.YEAR, value: year },
						{ key: GsitConstants.FIELDS.RFC_NUM, value: num }
					]
				);
				return clone;
			}
		},
		GET_FILTERDATA: {
			url: 'gsit/rfc/filters/generic',
			method: 'GET',
			parametrize: (field: string) => {
				const clone = Object.assign({}, WebServices.GSIT_RFC.GET_FILTERDATA);
				clone.url = UrlUtils.addParameters(
					clone.url,
					[
						{ key: 'field', value: field }
					]
				);
				return clone;
			}
		},
		GET_DOWNLOAD: {
			url: 'gsit/rfc/download',
			method: 'GET',
			responseType: 'json',
			parametrize: (doc: GsitRfcDocumentModel) => {
				const clone = Object.assign({}, WebServices.GSIT_RFC.GET_DOWNLOAD);
				// clone.url = UrlUtils.concat(clone.url,doc.r_object_id );

				clone.url = UrlUtils.addParameters(
					clone.url,
					[
						{ key: 'bv_program_code', value: doc.bv_program_code },
						{ key: 'filename', value: doc.r_object_id }
					]
				);
				return clone;
			}
		}
	};

	public static readonly GSIT_SHIPMENT: WebServicesDefinition = {
		GET_ALL: {
			url: 'gsit/shipment/search',
			method: 'GET',
			parametrize: (programCode: string, year: number, rfcNum: number, shipmentNum: string, importer: string, exporter: string, objectType: string) => {
				const clone = Object.assign({}, WebServices.GSIT_SHIPMENT.GET_ALL);
				clone.url = UrlUtils.addParameters(
					clone.url,
					[
						{ key: GsitConstants.FIELDS.PROGRAM_CODE, value: programCode },
						{ key: GsitConstants.FIELDS.YEAR, value: year },
						{ key: GsitConstants.FIELDS.RFC_NUM, value: rfcNum },
						{ key: GsitConstants.FIELDS.SHIPMENT_NUM, value: shipmentNum },
						{ key: GsitConstants.FIELDS.IMPORTER, value: importer },
						{ key: GsitConstants.FIELDS.EXPORTER, value: exporter },
						{ key: GsitConstants.FIELDS.OBJECT_TYPE, value: objectType }
					]
				);
				return clone;
			}
		},
		GET_FILTERDATA: {
			url: 'gsit/shipment/filters/generic',
			method: 'GET',
			parametrize: (field: string) => {
				const clone = Object.assign({}, WebServices.GSIT_SHIPMENT.GET_FILTERDATA);
				clone.url = UrlUtils.addParameters(
					clone.url,
					[
						{ key: 'field', value: field }
					]
				);
				return clone;
			}
		},
		GET_AUTOCOMPLETEDATA: {
			url: 'gsit/shipment/autocomplete/generic',
			method: 'GET',
			parametrize: (field: string, value: any) => {
				const clone = Object.assign({}, WebServices.GSIT_SHIPMENT.GET_AUTOCOMPLETEDATA);
				clone.url = UrlUtils.addParameters(
					clone.url,
					[
						{ key: 'field', value: field },
						{ key: 'value', value: value }
					]
				);
				return clone;
			}
		},
		GET_DOWNLOAD: {
			url: 'gsit/shipment/download',
			method: 'GET',
			responseType: 'json',
			parametrize: (doc: GsitShipmentDocumentModel) => {
				const clone = Object.assign({}, WebServices.GSIT_SHIPMENT.GET_DOWNLOAD);
				// clone.url = UrlUtils.concat(clone.url, doc.r_object_id);
				clone.url = UrlUtils.addParameters(
					clone.url,
					[
						{ key: 'bv_program_code', value: doc.bv_program_code },
						{ key: 'filename', value: doc.r_object_id }
					]
				);
				return clone;
			}
		}
	};

	public static readonly ISM_PURCHASE: WebServicesDefinition = {
		GET_ALL: {
			url: 'ism_purchase/purchase/search',
			method: 'GET',
			parametrize: (category: string,
				country: string,
				business_unit: string,
				buyer: string,
				type: string,
				manufacturer: string,
				command_number: string,
				request_number: string,
				supplier: string,
				project_code: string,
				status: string,
				completion_date_from: string,
				completion_date_until: string) => {
				const clone = Object.assign({}, WebServices.ISM_PURCHASE.GET_ALL);
				let date_from: string | undefined = StringUtils.isBlank(completion_date_from) ? undefined : completion_date_from;
				let date_until: string | undefined = StringUtils.isBlank(completion_date_until) ? undefined : completion_date_until;
				if (date_from !== undefined || date_until !== undefined) {
					// Date completion form
					date_from = completion_date_from ? completion_date_from : '1970-01-01';
					// Date completion until
					date_until = completion_date_until ? completion_date_until : undefined;
					if (date_until) {
						const newDate = new Date(completion_date_until);
						newDate.setDate(newDate.getDate() + 1);
						newDate.setHours(0);
						newDate.setMinutes(0);
						newDate.setSeconds(0);
						newDate.setMilliseconds(0);
						date_until = newDate.toISOString();
					} else {
						date_until = '2099-12-31';
					}
				}
				clone.url = UrlUtils.addParameters(
					clone.url,
					[
						{ key: 'category', value: category },
						{ key: 'country', value: country },
						{ key: 'business_unit', value: business_unit },
						{ key: 'buyer', value: buyer },
						{ key: 'type', value: type },
						{ key: 'manufacturer', value: manufacturer },
						{ key: 'command_number', value: command_number },
						{ key: 'request_number', value: request_number },
						{ key: 'supplier', value: supplier },
						{ key: 'project_code', value: project_code },
						{ key: 'status', value: status },
						{ key: 'completion_date_from', value: date_from },
						{ key: 'completion_date_until', value: date_until },
					]
				);
				return clone;
			}
		},
		GET_FILTERDATA: {
			url: 'ism_purchase/purchase/filters/generic',
			method: 'GET',
			parametrize: (field: string) => {
				const clone = Object.assign({}, WebServices.ISM_PURCHASE.GET_FILTERDATA);
				clone.url = UrlUtils.addParameters(
					clone.url,
					[
						{ key: 'field', value: field }
					]
				);
				return clone;
			}
		},
		GET_DOWNLOAD: {
			url: 'ism_purchase/purchase/download',
			method: 'GET',
			responseType: 'json',
			parametrize: (doc: IsmPurchaseDocumentModel) => {
				const clone = Object.assign({}, WebServices.ISM_PURCHASE.GET_DOWNLOAD);

				clone.url = UrlUtils.addParameters(
					clone.url,
					[
						{ key: 'request_number', value: doc.request_number },
						{ key: 'filename', value: doc.id }
					]
				);
				return clone;
			}
		}
	};

	public static readonly SEQUENCE_6: WebServicesDefinition = {
		GET_ALL: {
			url: 'sequence6/seqcase/search',
			method: 'GET',
			parametrize: (filters: Sequence6FilterModel) => {
				const clone = Object.assign({}, WebServices.SEQUENCE_6.GET_ALL);
				let date_from: string | undefined = StringUtils.isBlank(filters.archive_date_from) ? undefined : filters.archive_date_from;
				let date_until: string | undefined = StringUtils.isBlank(filters.archive_date_until) ? undefined : filters.archive_date_until;
				if (date_from !== undefined || date_until !== undefined) {
					// Archive date from
					date_from = filters.archive_date_from ? filters.archive_date_from : '1970-01-01';
					// Archive date until
					date_until = filters.archive_date_until ? filters.archive_date_until : undefined;
					if (date_until) {
						const newDate = new Date(date_until);
						newDate.setDate(newDate.getDate() + 1);
						newDate.setUTCHours(0);
						newDate.setUTCMinutes(0);
						newDate.setUTCSeconds(0);
						newDate.setUTCMilliseconds(0);
						date_until = newDate.toISOString();
					} else {
						date_until = '2099-12-31';
					}
				}
				let document_date_from: string | undefined = StringUtils.isBlank(filters.document_date_from) ? undefined : filters.document_date_from;
				let document_date_until: string | undefined = StringUtils.isBlank(filters.document_date_until) ? undefined : filters.document_date_until;
				if (document_date_from !== undefined || document_date_until !== undefined) {
					// Document date from
					document_date_from = filters.document_date_from ? filters.document_date_from : '1970-01-01';
					// Document date until
					document_date_until = filters.document_date_until ? filters.document_date_until : undefined;
					if (document_date_until) {
						const newDate = new Date(document_date_until);
						newDate.setDate(newDate.getDate() + 1);
						newDate.setUTCHours(0);
						newDate.setUTCMinutes(0);
						newDate.setUTCSeconds(0);
						newDate.setUTCMilliseconds(0);
						document_date_until = newDate.toISOString();
					} else {
						document_date_until = '2099-12-31';
					}
				}
				clone.url = UrlUtils.addParameters(
					clone.url,
					[
						{ key: Sequence6Constants.FIELDS.CASE_NUMBER, value: filters.case_number },
						{ key: Sequence6Constants.FIELDS.CASE_LABEL, value: filters.case_label === undefined ? undefined : encodeURI(filters.case_label) },
						{ key: Sequence6Constants.FIELDS.ARCHIVE_DATE_FROM, value: date_from },
						{ key: Sequence6Constants.FIELDS.ARCHIVE_DATE_UNTIL, value: date_until },
						{ key: Sequence6Constants.FIELDS.CATEGORY, value: filters.category },
						{ key: Sequence6Constants.FIELDS.DOCUMENT_TYPE, value: filters.document_type },
						{ key: Sequence6Constants.FIELDS.DOCUMENT_LABEL, value: filters.document_label === undefined ? undefined : encodeURI(filters.document_label) },
						{ key: Sequence6Constants.FIELDS.DOCUMENT_DATE_FROM, value: document_date_from },
						{ key: Sequence6Constants.FIELDS.DOCUMENT_DATE_UNTIL, value: document_date_until },
						{ key: Sequence6Constants.FIELDS.CLIENT_NAME, value: filters.client_name === undefined ? undefined : encodeURI(filters.client_name)},
						{ key: Sequence6Constants.FIELDS.CONTRACTING_AUTHORITY, value: filters.contracting_authority === undefined ? undefined : encodeURI(filters.contracting_authority)},
						{ key: Sequence6Constants.FIELDS.BUILDING_CITY, value: filters.building_city === undefined ? undefined : encodeURI(filters.building_city)},
						{ key: Sequence6Constants.FIELDS.POST_HOLDER_COORDINATORS, value: filters.post_holder_coordinators === undefined ? undefined : encodeURI(filters.post_holder_coordinators)},
						{ key: Sequence6Constants.FIELDS.DEPUTY_COORDINATOR, value: filters.deputy_coordinator === undefined ? undefined : encodeURI(filters.deputy_coordinator)},
						{ key: Sequence6Constants.FIELDS.IS_LAST_VERSION, value: filters.is_last_version }
					]
				);
				return clone;
			}
		},
		GET_FILTERDATA: {
			url: 'sequence6/seqcase/filters/generic',
			method: 'GET',
			parametrize: (field: string) => {
				const clone = Object.assign({}, WebServices.SEQUENCE_6.GET_FILTERDATA);
				clone.url = UrlUtils.addParameters(
					clone.url,
					[
						{ key: 'field', value: field }
					]
				);
				return clone;
			}
		},
		GET_DOWNLOAD: {
			url: 'sequence6/seqcase/download',
			method: 'GET',
			responseType: 'json',
			parametrize: (doc: Sequence6DocumentModel) => {
				const clone = Object.assign({}, WebServices.SEQUENCE_6.GET_DOWNLOAD);
				clone.url = UrlUtils.addParameters(
					clone.url,
					[
						{ key: 'case_number', value: doc.case_number },
						{ key: 'filename', value: ObjectUtils.readProperty(doc, 'id') }
					]
				);
				return clone;
			}
		},
		GET_AUTOCOMPLETEDATA: {
			url: 'sequence6/seqcase/autocomplete/generic',
			method: 'GET',
			parametrize: (field: string, value: any) => {
				const clone = Object.assign({}, WebServices.SEQUENCE_6.GET_AUTOCOMPLETEDATA);
				const encoded = encodeURI(value);
				clone.url = UrlUtils.addParameters(
					clone.url, [{ key: 'field', value: field }, { key: 'value', value: encoded }]
				);
				return clone;
			}
		}
	};

	public static readonly AWI: WebServicesDefinition = {
		GET_ALL: {
			url: 'awi/awidoc/search',
			method: 'GET',
			parametrize: (filters: AwiFilterModel) => {
				const clone = Object.assign({}, WebServices.AWI.GET_ALL);
				clone.url = UrlUtils.addParameters(
					clone.url,
					[
						{ key: AwiConstants.FIELDS.PROPOSAL_NUM, value: filters.proposal_num },
						{ key: AwiConstants.FIELDS.ISSUING_YEAR, value: filters.proposal_issuing_year },
						{ key: AwiConstants.FIELDS.BUSINESS_UNIT, value: filters.proposal_bu },
						{ key: AwiConstants.FIELDS.CLIENT_ZIG_NUM, value: filters.client_zig_number },
						{ key: AwiConstants.FIELDS.ZIG_CONTRACT_NUM, value: filters.proposal_zig_contract_num }
					]
				);
				return clone;
			}
		},
		GET_FILTERDATA: {
			url: 'awi/awidoc/filters/generic',
			method: 'GET',
			parametrize: (field: string) => {
				const clone = Object.assign({}, WebServices.AWI.GET_FILTERDATA);
				clone.url = UrlUtils.addParameters(
					clone.url,
					[
						{ key: 'field', value: field }
					]
				);
				return clone;
			}
		},
		GET_DOWNLOAD: {
			url: 'awi/awidoc/download',
			method: 'GET',
			responseType: 'json',
			parametrize: (doc: AwiDocumentModel) => {
				const clone = Object.assign({}, WebServices.AWI.GET_DOWNLOAD);
				clone.url = UrlUtils.addParameters(
					clone.url,
					[
						{ key: 'proposal_num', value: doc.proposal_num },
						{ key: 'filename', value: ObjectUtils.readProperty(doc, 'id') }
					]
				);
				return clone;
			}
		},
		GET_AUTOCOMPLETEDATA: {
			url: 'awi/awidoc/autocomplete/generic',
			method: 'GET',
			parametrize: (field: string, value: any) => {
				const clone = Object.assign({}, WebServices.AWI.GET_AUTOCOMPLETEDATA);
				const encoded = encodeURI(value);
				clone.url = UrlUtils.addParameters(
					clone.url, [{ key: 'field', value: field }, { key: 'value', value: encoded }]
				);
				return clone;
			}
		}
	};

	public static readonly ETQ_AUDIT: WebServicesDefinition = {
    		GET_ALL: {
    			url: 'etq/auditdoc/search',
    			method: 'GET',
    			parametrize: (filters: EtqFilterModel) => {
					const clone = Object.assign({}, WebServices.ETQ_AUDIT.GET_ALL);
					clone.url = UrlUtils.addParameters(
						clone.url,
						[
							{ key: EtqConstants.FIELDS_AUDIT.AUDIT_NUMBER.name, value: filters.auditRef },
							{ key: EtqConstants.FIELDS_AUDIT.CHECKLIST_NUMBER.name, value: filters.checklistRef },
							{ key: EtqConstants.FIELDS_AUDIT.CA_NUMBER.name, value: filters.caRef },
							{ key: EtqConstants.FIELDS_AUDIT.ACTION_NUMBER.name, value: filters.actionRef }
						]
					);
					return clone;
				}
    		},
			GET_FILTERDATA: {
				url: 'etq/auditdoc/filters/generic',
				method: 'GET',
				parametrize: (field: string) => {
					const clone = Object.assign({}, WebServices.ETQ_AUDIT.GET_FILTERDATA);
					clone.url = UrlUtils.addParameters(
						clone.url,
						[
							{ key: 'field', value: field }
						]
					);
					return clone;
				}
			},
			GET_DOWNLOAD: {
				url: 'etq/auditdoc/download',
				method: 'GET',
				responseType: 'json',
				parametrize: (doc: EtqDocumentModel) => {
					const clone = Object.assign({}, WebServices.ETQ_AUDIT.GET_DOWNLOAD);
					console.log("ObjectUtils.readProperty(doc, 'id'):" + ObjectUtils.readProperty(doc, 'id'));
					clone.url = UrlUtils.addParameters(
						clone.url,
						[
							{ key: EtqConstants.FIELDS_AUDIT.AUDIT_NUMBER.name, value: doc.auditRef },
							{ key: 'filename', value: ObjectUtils.readProperty(doc, 'id') }
						]
					);
					return clone;
				}
			}
    	};

		public static readonly ETQ_INCIDENT: WebServicesDefinition = {
    		GET_ALL: {
    			url: 'etq/incidentdoc/search',
    			method: 'GET',
    			parametrize: (filters: EtqFilterModel) => {
					const clone = Object.assign({}, WebServices.ETQ_INCIDENT.GET_ALL);
					clone.url = UrlUtils.addParameters(
						clone.url,
						[
							{ key: EtqConstants.FIELDS_INCIDENT.INCIDENT_NUMBER.name, value: filters.incidentRef },
							{ key: EtqConstants.FIELDS_AUDIT.CA_NUMBER.name, value: filters.caRef },
							{ key: EtqConstants.FIELDS_AUDIT.ACTION_NUMBER.name, value: filters.actionRef }
						]
					);
					return clone;
				}
    		},
			GET_FILTERDATA: {
				url: 'etq/incidentdoc/filters/generic',
				method: 'GET',
				parametrize: (field: string) => {
					const clone = Object.assign({}, WebServices.ETQ_INCIDENT.GET_FILTERDATA);
					clone.url = UrlUtils.addParameters(
						clone.url,
						[
							{ key: 'field', value: field }
						]
					);
					return clone;
				}
			},
			GET_DOWNLOAD: {
				url: 'etq/incidentdoc/download',
				method: 'GET',
				responseType: 'json',
				parametrize: (doc: EtqDocumentModel) => {
					const clone = Object.assign({}, WebServices.ETQ_INCIDENT.GET_DOWNLOAD);
					clone.url = UrlUtils.addParameters(
						clone.url,
						[
							{ key: EtqConstants.FIELDS_INCIDENT.INCIDENT_NUMBER.name, value: doc.incidentRef },
							{ key: 'filename', value: ObjectUtils.readProperty(doc, 'id') }
						]
					);
					return clone;
				}
			}
    	};

		public static readonly ETQ_COMPLAINT: WebServicesDefinition = {
    		GET_ALL: {
    			url: 'etq/complaintdoc/search',
    			method: 'GET',
    			parametrize: (filters: EtqFilterModel) => {
					const clone = Object.assign({}, WebServices.ETQ_COMPLAINT.GET_ALL);
					clone.url = UrlUtils.addParameters(
						clone.url,
						[
							{ key: EtqConstants.FIELDS_COMPLAINT.COMPLAINT_NUMBER.name, value: filters.complaintRef },
							{ key: EtqConstants.FIELDS_AUDIT.CA_NUMBER.name, value: filters.caRef },
							{ key: EtqConstants.FIELDS_AUDIT.ACTION_NUMBER.name, value: filters.actionRef }
						]
					);
					return clone;
				}
    		},
			GET_FILTERDATA: {
				url: 'etq/complaintdoc/filters/generic',
				method: 'GET',
				parametrize: (field: string) => {
					const clone = Object.assign({}, WebServices.ETQ_COMPLAINT.GET_FILTERDATA);
					clone.url = UrlUtils.addParameters(
						clone.url,
						[
							{ key: 'field', value: field }
						]
					);
					return clone;
				}
			},
			GET_DOWNLOAD: {
				url: 'etq/complaintdoc/download',
				method: 'GET',
				responseType: 'json',
				parametrize: (doc: EtqDocumentModel) => {
					const clone = Object.assign({}, WebServices.ETQ_COMPLAINT.GET_DOWNLOAD);
					clone.url = UrlUtils.addParameters(
						clone.url,
						[
							{ key: EtqConstants.FIELDS_COMPLAINT.COMPLAINT_NUMBER.name, value: doc.complaintRef },
							{ key: 'filename', value: ObjectUtils.readProperty(doc, 'id') }
						]
					);
					return clone;
				}
			}
    	};

		public static readonly ZIG: WebServicesDefinition = {
			GET_ALL: {
				url: 'zig/doc/search',
				method: 'GET',
				parametrize: (filters: ZigFilterModel) => {
					const clone = Object.assign({}, WebServices.ZIG.GET_ALL);
					let invoiceNumber_from: string | undefined = StringUtils.isBlank(filters.invoiceNumber_from) ? undefined : filters.invoiceNumber_from;
					let invoiceNumber_until: string | undefined = StringUtils.isBlank(filters.invoiceNumber_until) ? undefined : filters.invoiceNumber_until;
					if (invoiceNumber_from !== undefined || invoiceNumber_until !== undefined) {
						// Invoice from
						invoiceNumber_from = filters.invoiceNumber_from ? filters.invoiceNumber_from : '0';
						// Invoice until
						invoiceNumber_until = filters.invoicingDate_until ? filters.invoicingDate_until : undefined;
						if (invoiceNumber_until) {
						} else {
							invoiceNumber_until = '999999999';
						}
					}

					let date_from: string | undefined = StringUtils.isBlank(filters.invoicingDate_from) ? undefined : filters.invoicingDate_from;
					let date_until: string | undefined = StringUtils.isBlank(filters.invoicingDate_until) ? undefined : filters.invoicingDate_until;
					if (date_from !== undefined || date_until !== undefined) {
						// Date completion form
						date_from = filters.invoicingDate_from ? filters.invoicingDate_from : '1970-01-01';
						// Date completion until
						date_until = filters.invoicingDate_until ? filters.invoicingDate_until : undefined;
						if (date_until) {
							const newDate = new Date(date_until);
							newDate.setDate(newDate.getDate() + 1);
							newDate.setUTCHours(0);
							newDate.setUTCMinutes(0);
							newDate.setUTCSeconds(0);
							newDate.setUTCMilliseconds(0);
							date_until = newDate.toISOString();
						} else {
							date_until = '2099-12-31';
						}
					}
					clone.url = UrlUtils.addParameters(
						clone.url,
						[
							{ key: ZigConstants.FIELDS.INVOICE_NUM_FROM, value: filters.invoiceNumber_from },
							{ key: ZigConstants.FIELDS.INVOICE_NUM_UNTIL, value: filters.invoiceNumber_until },
							{ key: ZigConstants.FIELDS.DOCUMENT_TYPE, value: filters.documentType },
							{ key: ZigConstants.FIELDS.COMPANY_NUMBER, value: filters.companyNumber },
							{ key: ZigConstants.FIELDS.CLIENT_NUMBER, value: filters.clientNumber },
							{ key: ZigConstants.FIELDS.BUSINESS_NUMBER, value: filters.businessNumber },
							{ key: ZigConstants.FIELDS.INVOICING_DATE_FROM, value: date_from },
							{ key: ZigConstants.FIELDS.INVOICING_DATE_UNTIL, value: date_until }
						]
					);
					return clone;
				}
			},
			GET_FILTERDATA: {
				url: 'zig/doc/filters/generic',
				method: 'GET',
				parametrize: (field: string) => {
					const clone = Object.assign({}, WebServices.ZIG.GET_FILTERDATA);
					clone.url = UrlUtils.addParameters(
						clone.url,
						[
							{ key: 'field', value: field }
						]
					);
					return clone;
				}
			},
			GET_EXPORT_FILTERDATA: {
				url: 'zig/doc/filters/invoicedate',
				method: 'GET',
				parametrize: (field: string) => {
					const clone = Object.assign({}, WebServices.ZIG.GET_EXPORT_FILTERDATA);
					clone.url = UrlUtils.addParameters(
						clone.url,
						[
							{ key: 'field', value: field }
						]
					);
					return clone;
				}
			},
			GET_DOWNLOAD: {
				url: 'zig/doc/download',
				method: 'GET',
				responseType: 'json',
				parametrize: (doc: ZigDocumentModel) => {
					const clone = Object.assign({}, WebServices.ZIG.GET_DOWNLOAD);
					clone.url = UrlUtils.addParameters(
						clone.url,
						[
							{ key: 'invoiceNumber', value: doc.invoiceNumber },
							{ key: 'filename', value: ObjectUtils.readProperty(doc, 'id') }
						]
					);
					return clone;
				}
			},
			GET_AUTOCOMPLETEDATA: {
				url: 'zig/doc/autocomplete/generic',
				method: 'GET',
				parametrize: (field: string, value: any) => {
					const clone = Object.assign({}, WebServices.ZIG.GET_AUTOCOMPLETEDATA);
					const encoded = encodeURI(value);
					clone.url = UrlUtils.addParameters(
						clone.url, [{ key: 'field', value: field }, { key: 'value', value: encoded }]
					);
					return clone;
				}
			},
			POST_ZIPRESULTS_DOWNLOAD: {
				url: 'zig/doc/zipresultsdownload',
				method: 'POST',
				responseType: 'json',
				parametrize: (docIds: string[]) => {
					const clone = Object.assign({}, WebServices.ZIG.POST_ZIPRESULTS_DOWNLOAD);
					const jsonBodyArray = JSON.stringify({ keys: docIds });
					//const itemsKeysString = docIds.join(",");
					console.log("jsonBodyArray:" + jsonBodyArray);
					clone.body = jsonBodyArray;
					/*
					clone.url = UrlUtils.addParameters(
						clone.url,
						[
							{ key: 'resultKeys', value: itemsKeysString }
						]
					);
					*/ 
					return clone;
				}
			},
			GET_ZIPPATH_DOWNLOAD: {
				url: 'zig/doc/zippathdownload',
				method: 'GET',
				responseType: 'json',
				parametrize: (path: string) => {
					const clone = Object.assign({}, WebServices.ZIG.GET_ZIPPATH_DOWNLOAD);
					clone.url = UrlUtils.addParameters(
						clone.url,
						[
							{ key: 'path', value: path }
						]
					);
					return clone;
				}
			},
			GET_ZIPFILES_GENERATED: {
				url: 'zig/doc/zipfileslist',
				method: 'GET',
				responseType: 'json',
				parametrize: () => {
					const clone = Object.assign({}, WebServices.ZIG.GET_ZIPFILES_GENERATED);
					return clone;
				}
			}

		};

}
