import { FieldDefinition } from '../models/field-definition';

export abstract class ZigConstants {

    public static readonly FIELDS = {
        INVOICE_NUM: 'invoiceNumber',
        INVOICE_NUM_FROM: 'invoiceNumber_from',
        INVOICE_NUM_UNTIL: 'invoiceNumber_until',
        DOCUMENT_TYPE: 'documentType',
        COMPANY_NUMBER: 'companyNumber',
        CLIENT_NUMBER: 'clientNumber',
        BUSINESS_NUMBER: 'businessNumber',
        INVOICING_DATE: 'invoicingDate',
        INVOICING_DATE_FROM: 'invoicingDate_from',
		INVOICING_DATE_UNTIL: 'invoicingDate_until',
    };

    public static readonly FIELDS_ZIG: {
        [key: string]: FieldDefinition
    } = {
        INVOICE_NUM: { name: 'invoiceNumber', detailMain: true, columnIndex: 1 },
        INVOICE_NUM_FROM: { name: 'invoiceNumber_from' },
        INVOICE_NUM_UNTIL: { name: 'invoiceNumber_until' },
        DOCUMENT_TYPE: { name: 'documentType', detailMain: true, columnIndex: 2 },
        COMPANY_NUMBER: { name: 'companyNumber', detailMain: true, columnIndex: 3 },
        CLIENT_NUMBER: { name: 'clientNumber', detailMain: true, columnIndex: 4 },
        BUSINESS_NUMBER: { name: 'businessNumber', detailMain: true, columnIndex: 5 },
        INVOICING_DATE: { name: 'invoicingDate', detailMain: true, dateFormat: 'MM/dd/yyyy' , columnIndex: 6 },
        INVOICING_DATE_FROM: { name: 'invoicingDate_from' },
        INVOICING_DATE_UNTIL: { name: 'invoicingDate_until' },
    };

    public static readonly FIELDS_ZIG_EXPORT: {
        [key: string]: FieldDefinition
    } = {
        COMPANY_NUMBER: { name: 'companyNumber', detailMain: true, columnIndex: 1 },
        INVOICING_DATE: { name: 'invoicingDate', detailMain: true, dateFormat: 'yyyy' , columnIndex: 2 },
        ZIP_DATE: { name: 'zipDate', detailMain: true, dateFormat: 'MM/dd/yyyy' , columnIndex: 3 },
        STATUS: { name: 'status', detailMain: true, columnIndex: 4 },
    };
}
