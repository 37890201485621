import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ZigFilterComponent } from './zig-filter/zig-filter.component';
import { ZigDocComponent } from './zig-doc/zig-doc.component';
import { ZigDocExportComponent } from './zig-doc-export/zig-doc-export.component';
import { ZigMetadataComponent } from './zig-metadata/zig-metadata.component';
import { AppSharedModule } from 'src/app/app-shared.module';
import { ZigDocExportListComponent } from './zig-doc-export-list/zig-doc-export-list.component';
import { ZigMetadataExportComponent } from './zig-metadata-export/zig-metadata-export.component';

@NgModule({
    declarations: [
        ZigFilterComponent,
        ZigMetadataComponent,
        ZigDocComponent,
        ZigDocExportComponent,
        ZigDocExportListComponent,
        ZigMetadataExportComponent
    ],
    imports: [
        AppSharedModule,
        IonicModule
    ],
    entryComponents: [
    ],
    exports: [
        ZigFilterComponent,
        ZigMetadataComponent,
        ZigDocComponent,
        ZigDocExportComponent,
        ZigDocExportListComponent,
        ZigMetadataExportComponent
    ]
})
export class ZigComponentsModule { }
